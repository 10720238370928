
.ArticleSearch div .FormLine .row {
	padding: 3px 0px !important;
}

.ArticleSearch-taxonomy-link-container {
	padding: 3px 0px 0px 0px;
	text-align: center;
}

.ArticleSearch-taxonomy-link {
	cursor: pointer;
	color: #9608A7 !important;
	width: 100%;
	text-align: center;
}

.ArticleSearch-tree-taxonomy {
	margin: 10px 0px;
}

.ArticleSearch-img {
	display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}