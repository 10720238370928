
.Tab {
}

@media (min-width: 0px) {
    .Tab-menu {
    	padding: 20px 0px;
        /*margin-left: 20px;*/
    }
}

@media (min-width: 768px) {
    .Tab-menu {
        position: absolute;
        padding: 20px 0px;
        margin-left: 0px;
    }
}

.Tab-menu-el {
	cursor: pointer;
	color: #010101;
    text-transform: uppercase;
    font-family: "Fjalla One", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    padding: 10px 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    width: inherit;
    max-width:inherit;
}

.Tab-menu-el:hover {
	background-color: rgba(0, 159, 227, 0.05);
}

.Tab-menu-el-selected {
    border-left: 4px solid #e40613;
}

.Tab-menu-el-empty {
    height: 15px;
}