.Loading {
    width: 100%;
    height: 100%;
    position: relative;
}

.Loading-logo {
    width: 50px;
    animation: rotate 3s infinite;
    -webkit-animation: rotate 3s infinite;
    -moz-animation: rotate 3s infinite;
    animation-timing-function: cubic-bezier(1,0,.5,1);
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
}

.Loading-logo img {
    width: 50px;
}

@-webkit-keyframes rotate {
    from { 
        -webkit-transform: rotate(0deg);
    } 
    to { 
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from { 
        transform: rotate(0deg);
    } 
    to { 
        transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from { 
        -moz-transform: rotate(0deg);
    } 
    to { 
        -moz-transform: rotate(360deg);
    }
}